// import react from 'react'
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useAuthContext } from '../../hooks/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';
// import Papa from 'papaparse';
import style from './style.module.scss';
import { displaySuccess, displayWarning } from "../../utils/functions/messageToast";
// import { toRespondTickets } from '../../utils/functions/functionsLoadDataForm';
import { Input } from '../../components/ui/inputs';
import Select from 'react-select';
// import { DataLoginProps } from '../../utils/functions/functionsInterfaces';
import { ButtonLogin } from '../../components/ui/buttons';
import { apiGetFormsAndLogin } from '../../services/apiClient';
import { SelectAmbient } from '../../components/ui/selects';
// import { text } from 'stream/consumers';
import { setupApiGetTabAndSkill } from '../../services/setupApis';
// import { set } from 'date-fns';

export default function Pendency() {
  const useIsomorphicEffect = useEffect;

  const { user } = useAuthContext();

  const [cacheCleared, setCacheCleared] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string[]>([]);
  const [optionsTabs, setOptionsTabs] = useState<any[]>([]);
  const [optionsFlows, setOptionsFlows] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [pageDeploy, setPageDeploy] = useState(false);
  const [instance, setInstance] = useState('');
  const [ambient, setAmbient] = useState('');
  const ambientLogged = user?.ambient as string
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [sectionLogin, setSectionLogin] = useState(true);
  const [description, setDescription] = useState(false);

  const recaptchaRef = useRef<any>(null);
  const AUTHORIZATION_RA_KEY_TO = 'AuthorizationRA_to' as const
  const AUTHORIZATION_KEY_TO = 'Authorization_to' as const
  const CLIENT_TO = 'client_to' as const

  // const keyWebSite = import.meta.env.MODE === "production"
  //   ? import.meta.env.VITE_RECAPTCHA_KEY_PROD
  //   : import.meta.env.VITE_RECAPTCHA_KEY_DEV;

  const keyWebSite = "6Lf0nTEpAAAAAIKEgRudFU1j6wxJAoPKUF3EqDk5"; // temporario

  useIsomorphicEffect(() => {
    const executeClearCache = async () => {
      await clearCacheClient();
    };
    executeClearCache();

    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }

    const tokenTo = localStorage.getItem(AUTHORIZATION_KEY_TO);
    const clientTo = localStorage.getItem(CLIENT_TO);

    if (tokenTo && clientTo) {
      const savedDescription = localStorage.getItem('description') === 'true';
      setDescription(savedDescription);

      (async () => {
        const api = setupApiGetTabAndSkill(undefined, localStorage.getItem(AUTHORIZATION_KEY_TO) as string)
        const response = await api.get(`/get-tab?&ambient=${ambientLogged}`)

        const allTabs = response.data.data.map((tab: any) => {
          return {
            value: tab.id,
            label: tab.name,
          }
        })
        setOptionsTabs(allTabs)
      })()

      setEmail(localStorage.getItem("email_to") as string);
      setInstance(localStorage.getItem("instance_to") as string);
      setAmbient(localStorage.getItem("ambient_to") as string);
      setPassword('');

      setIsModalOpen(false);
      setDescription(true);
      setPageDeploy(true);
    } else {
      setEmail('');
      setPassword('');
      setInstance('');
      setAmbient('');
      localStorage.removeItem(AUTHORIZATION_KEY_TO);
      localStorage.removeItem(AUTHORIZATION_RA_KEY_TO);
      localStorage.removeItem(CLIENT_TO);
      setIsLoggedIn(true);
      setDescription(false);
      setIsModalOpen(false);
      setPageDeploy(false);
    }
  }, [selectedOption]);

  const clearCacheClient = async () => {
    try {
      const cacheKeys = await caches.keys();

      if (cacheKeys.length > 0) {
        await Promise.all(cacheKeys.map(key => caches.delete(key)));
        setCacheCleared(true);

        // if (!window.location.search.includes('reloaded=true')) {
        //   window.location.replace(window.location.href + '?reloaded=true');
        // }
      }
    } catch (error: any) {
      return error
    }
  };

  const onSubmitWithReCAPTCHA = async (event: FormEvent) => {
    event.preventDefault();

    if (ambient === '') {
      displayWarning('Selecione o ambiente!');
      return;
    }

    if (instance === '') {
      displayWarning('Preencha o domínio da empresa!');
      return;
    }

    if (email === '') {
      displayWarning('Preencha o email!');
      return;
    }

    if (password === '') {
      displayWarning('Preencha a senha!');
      return;
    }

    setLoading(true);

    const response = await apiGetFormsAndLogin.post('/login-tech', {
      slug: instance,
      email: email,
      password: password,
      ambient: ambient,
      recaptcha_token: "",
    })

    localStorage.setItem(
      AUTHORIZATION_KEY_TO,
      response.data.tokens.Authorization
    )
    localStorage.setItem(
      AUTHORIZATION_RA_KEY_TO,
      response.data.tokens.AuthorizationRA
    )

    localStorage.setItem(CLIENT_TO, response.data.company.name)

    setIsModalOpen(true)
    setIsModalOpen(true)
    setLoading(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAmbient(event.target.value);
  };

  const handleConfirm = async () => {
    setLoadingButton(true);
    const getTabs = setupApiGetTabAndSkill(undefined, localStorage.getItem(AUTHORIZATION_KEY_TO) as string)
    const responseTabs = await getTabs.get(`/get-tab?&ambient=${ambientLogged}`)

    const allTabs = responseTabs.data.data.map((tab: any) => {
      return {
        value: tab.id,
        label: tab.name,
      }
    })
    setOptionsTabs(allTabs)

    const getFlows = setupApiGetTabAndSkill(undefined, localStorage.getItem(AUTHORIZATION_RA_KEY_TO) as string)
    const responseFlows = await getFlows.get(`/get-all-flow-from?&ambient=${ambient}`)

    const allFlows = responseFlows.data.allFlows.map((flow: any) => {
      return {
        value: flow.id,
        label: flow.name,
      }
    })
    setOptionsFlows(allFlows)

    localStorage.setItem("email_to", email);
    localStorage.setItem("instance_to", instance);
    localStorage.setItem("ambient_to", ambient);

    setEmail(localStorage.getItem("email_to") as string);
    setInstance(localStorage.getItem("instance_to") as string);
    setAmbient(localStorage.getItem("ambient_to") as string);
    setPassword('');


    setSectionLogin(true);
    setIsLoggedIn(true);
    setIsModalOpen(false);
    setPageDeploy(true);
    setDescription(true);
    setLoadingButton(false);

    displaySuccess('Login efetuado com sucesso!')
  };

  const handleDecline = async () => {
    setEmail('');
    setInstance('');
    setAmbient('');
    setPassword('');

    localStorage.removeItem(AUTHORIZATION_KEY_TO);
    localStorage.removeItem(AUTHORIZATION_RA_KEY_TO);
    localStorage.removeItem(CLIENT_TO);
    localStorage.removeItem("email_to");
    localStorage.removeItem("instance_to");
    localStorage.removeItem("ambient_to");

    setSectionLogin(false);
    setIsLoggedIn(false);
    setIsModalOpen(false);
    setPageDeploy(false);
    setDescription(false);
  };

  const handleCancel = async () => {
    setEmail('');
    setPassword('');
    setInstance('');
    setAmbient('');
    localStorage.removeItem(AUTHORIZATION_KEY_TO);
    localStorage.removeItem(AUTHORIZATION_RA_KEY_TO);
    localStorage.removeItem(CLIENT_TO);
    localStorage.removeItem("email_to");
    localStorage.removeItem("instance_to");
    localStorage.removeItem("ambient_to");
    setIsLoggedIn(true);
    setDescription(false);
    setIsModalOpen(false);
    setPageDeploy(false);
  }

  const handleSelect = async (selectedOptions: any) => {
    const selectedValues = selectedOptions.map((option: any) => option.value);
    setSelectedOption(selectedValues);
  }

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      maxWidth: "400px",
      borderRadius: "8px",
      boxShadow: "none",
      textAlign: "left",
    }),
    options: (provided: any, state: any) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? '#007bff' : 'white',
    }),
  };

  return (
    <div>
      <Helmet>
        <title>Tech Forms - Deploy</title>
      </Helmet>

      <div className={style.container}>
        <div className={style.containerMain}>

          {sectionLogin && (
            <>
              <h4 className={style.aviso}>Faça login no ambiente que vai fazer o deploy</h4>
              <section className={style.sectionLoginTo}>

                <div className={style.login}>
                  <form onSubmit={onSubmitWithReCAPTCHA}>

                    <SelectAmbient value={ambient} onChange={handleChange}>
                      <option value="" disabled>
                        Selecione o ambiente
                      </option>
                      <option value="prod">Produção</option>
                      <option value="hml">Homologação</option>
                    </SelectAmbient>

                    <Input
                      placeholder="Domínio da empresa"
                      type="text"
                      value={instance}
                      onChange={(e) => setInstance(e.target.value)}
                      autoComplete="company-domain"
                    />

                    <Input
                      placeholder="E-mail"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="email"
                    />

                    <Input
                      placeholder="Senha"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"
                    />

                    <ButtonLogin type="submit" loading={loading}>
                      Login
                    </ButtonLogin>
                  </form>
                </div>

              </section>
            </>
          )}

          {isLoggedIn && (
            <>
              <section className={style.section}>
                <h1>{description && (
                  <strong className={style.description} style={{ color: "white" }}>Você esta fazendo deploy DE: <strong style={{ color: "blue" }}>{user?.clientName}</strong> PARA: <strong style={{ color: "blue" }}>{localStorage.getItem("client_to")}</strong><button className={style.modalButtonCancel} onClick={handleCancel}>Cancelar</button></strong>
                )}</h1>
              </section>
            </>
          )}

          {isModalOpen && (
            <div className={style.modal}>
              <div className={style.modalContent}>
                <p>De: <strong>{user?.clientName}</strong> PARA: <strong>{localStorage.getItem("client_to")}</strong>?</p>
                <div className={style.modalActions}>
                  <button
                    className={style.modalButton}
                    onClick={handleConfirm}
                    disabled={loadingButton}
                  >
                    {loadingButton ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Sim'}
                  </button>
                  <button
                    className={style.modalButton}
                    onClick={handleDecline}
                  >
                    Não
                  </button>
                </div>
              </div>
            </div>
          )}

          {pageDeploy && (
            <section className={style.sectionInfos}>
              <div className={style.bodyDeploy}>
                <div className={style.items}>
                  <Select
                    options={optionsTabs}
                    styles={customStyles}
                    isMulti
                    onChange={(selectedOptions: any) => {
                      handleSelect(selectedOptions)
                    }}
                  />

                  <Select
                    options={optionsFlows}
                    styles={customStyles}
                    isMulti
                    onChange={(selectedOptions: any) => {
                      handleSelect(selectedOptions)
                    }}
                  />
                </div>
              </div>
            </section>
          )}

        </div>
      </div>
    </div>
  );
}
