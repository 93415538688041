import { Link, Outlet, useLocation } from 'react-router-dom';
import style from './style.module.scss';
import { Helmet } from 'react-helmet-async';
import { Header } from '../../components/Header';
import { useEffect, useState } from 'react';

export default function Home() {
  const [activeButton, setActiveButton] = useState("");

  const useIsomorphicEffect = useEffect;
  const [cacheCleared, setCacheCleared] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname.split("/").pop() || "listForms";
    setActiveButton(currentPath);
  }, [location]);

  useIsomorphicEffect(() => {
    const executeClearCache = async () => {
      await clearCacheClient();
    };
    executeClearCache();
  }, []);

  const clearCacheClient = async () => {
    try {
      const cacheKeys = await caches.keys();

      if (cacheKeys.length > 0) {
        await Promise.all(cacheKeys.map(key => caches.delete(key)));
        setCacheCleared(true);

        // if (!window.location.search.includes('reloaded=true')) {
        //   window.location.replace(window.location.href + '?reloaded=true');
        // }
      }
    } catch (error: any) {
      return error
    }
  };

  const sectionOptions = [
    { href: "listForms", label: 'Listar Formulários' },
    { href: "createForm", label: 'Criar novo formulário' },
    { href: "createTemplate", label: 'Criar form pelo payload' },
    { href: "tabulation", label: 'Tabulação em massa' },
    { href: "createSkills", label: 'Criar Skills' },
    { href: "pendency", label: 'Responder pendencias em massa' },
    { href: "updateForm", label: 'Edição de formulário' }
  ];

  const sectionOptionsTests = [
    { href: "deploy", label: 'Deploy' },
    { href: "listFormsExcluded", label: 'Listar Formulários Excluídos' }
  ]

  const handleButtonClick = (href: string) => {
    setActiveButton(href);
  };

  return (
    <>
      <Helmet>
        <title>Tech Forms - Home</title>
      </Helmet>

      <Header />
      <div className={style.mainContainer}>

        <aside className={style.sectionContainer}>
          {sectionOptions.map((button) => (
            <Link to={button.href} key={button.label}>
              <button
                onClick={() => handleButtonClick(button.href)}
                className={`${style.button} ${activeButton === button.href ? style.buttonActive : ''} ${button.href === 'updateForm' ? style.buttonDisabled : ''}`}
                disabled={button.href === 'updateForm'}
                title={button.href === 'updateForm' ? 'Selecione o formulário pela listagem' : ''}
              >
                {button.label}
              </button>
            </Link>
          ))}

          {import.meta.env.MODE !== "production" && (
            <>
              <h3>Testes</h3>
              {sectionOptionsTests.map((button) => (
                <Link to={button.href} key={button.label}>
                  <button
                    onClick={() => handleButtonClick(button.href)}
                    className={`${style.button} ${activeButton === button.href ? style.buttonActive : ''
                      }`}
                  >
                    {button.label}
                  </button>
                </Link>
              ))}
            </>
          )}

        </aside>

        <main className={style.contentContainer}>
          <Outlet />
        </main>

      </div>
    </>
  );
}