import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faSpinner, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Helmet } from 'react-helmet-async';
import style from './style.module.scss';
import { getAllForms, getFormByIdItemId } from '../../utils/functions/functionsLoadDataForm';
import { InputsInfosPages } from '../../components/ui/inputs';
import { ExcludedForm, getAllFormsExcludeds } from '../../utils/functions/functionsForms';
import { ButtonRemoveForm } from '../../components/ui/buttons';
import { displayError } from '../../utils/functions/messageToast';

export default function ListForms() {
  const navigate = useNavigate();
  const { authorizationRA, user } = useAuthContext();
  const ambient = user?.ambient as string;
  const clientId = user?.clientId as string;
  const [allForm, setAllForm] = useState<any[]>([]);
  const [countForms, setCountForms] = useState(0);
  const [loadingPage, setLoadingPage] = useState(false);
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [formsExcluded, setFormsExcluded] = useState<string[]>([]);
  const itemsPerPage = 15;

  const useIsomorphicEffect = useEffect;
  const [cacheCleared, setCacheCleared] = useState(false);

  useIsomorphicEffect(() => {
    const executeClearCache = async () => {
      await clearCacheClient();
    };
    executeClearCache();
  }, []);

  const clearCacheClient = async () => {
    try {
      const cacheKeys = await caches.keys();

      if (cacheKeys.length > 0) {
        await Promise.all(cacheKeys.map(key => caches.delete(key)));
        setCacheCleared(true);

        // if (!window.location.search.includes('reloaded=true')) {
        //   window.location.replace(window.location.href + '?reloaded=true');
        // }
      }
    } catch (error: any) {
      return error
    }
  };

  useEffect(() => {
    if (authorizationRA) {
      getAllForms(
        setLoadingPage,
        authorizationRA,
        ambient,
        setAllForm,
        currentPage,
        itemsPerPage,
        setCountForms,
        setFormsExcluded
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationRA, ambient, currentPage, itemsPerPage]);


  const handleFormClick = (formId: string) => {
    setSearch('');
    navigate(`/section/updateForm?clientId=${clientId}&formId=${formId}`, { state: { formId } });
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => {
      const totalPages = Math.ceil(countForms / itemsPerPage);
      if (prevPage < totalPages) {
        return prevPage + 1;
      }
      return prevPage;
    });
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => {
      if (prevPage > 1) {
        return prevPage - 1;
      }
      return prevPage;
    });
  };

  const handleSearchForm = async () => {
    setLoadingPage(true);
    getFormByIdItemId(clientId, search, ambient, setAllForm, setSearch);
    setLoadingPage(false);
  };

  // const handleUpdatePage = () => {
  //   getAllFormsExcludeds(setFormsExcluded);
  // };

  return (
    <div>
      <Helmet>
        <title>Tech Forms - Listagem dos Formulários</title>
      </Helmet>

      <div className={style.container}>
        {loadingPage ? (
          <div className={style.spinnerContainer}>
            <FontAwesomeIcon icon={faSpinner} size="2x" style={{ color: "#ffffff" }} spin />
          </div>
        ) : (
          <>
            <div className={style.main}>

              <div className={style.infoInputForm}>
                <div>
                  <div className={style.inputFormId}>
                    <button
                      title="Página Anterior"
                      className={style.pagination}
                      onClick={handlePreviousPage}
                      disabled={currentPage <= 1}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} size="2x" />
                    </button>
                    <div className={style.divFormId}>
                      <span>Pesquisar</span>
                      <InputsInfosPages
                        placeholder='Id - ItemId - Alias'
                        type='text'
                        value={search}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          setSearch(event.target.value)
                        }
                      />
                      <button
                        title='Pesquisar Formulário'
                        onClick={() => {
                          handleSearchForm();
                        }}>
                        <FontAwesomeIcon className={style.iconSearch} icon={faMagnifyingGlass} />
                      </button>
                    </div>
                    <button
                      title="Próxima Página"
                      className={style.pagination}
                      onClick={handleNextPage}
                      disabled={currentPage >= Math.ceil(countForms / itemsPerPage)}
                    >
                      <FontAwesomeIcon icon={faArrowRight} size="2x" />
                    </button>
                  </div>

                  <div className={style.infoPage}>
                    <span>Página {currentPage} de {Math.ceil(countForms / itemsPerPage)}</span>
                  </div>
                </div>

              </div>

              <div className={style.divForm}>

                {allForm
                  .map((form: any) => (
                    <div className={style.divFormExcluded}>
                      <div key={"list"} onClick={() => { handleFormClick(form.id); setSearch(""); }} className={style.form}>
                        <div className={style.formRow}>
                          <span className={style['item-title']}><span className={style.nameItems}>Id: </span>{form.id}</span>
                          <span className={style['item-title']}><span className={style.nameItems}>Nome: </span>{form.name}</span>
                          <span className={style['item-title']}><span className={style.nameItems}>Criado em: </span>{format(new Date(form.createdAt), 'dd/MM/yyyy HH:mm', { locale: ptBR })}</span>
                        </div>
                        <div className={style.formRow}>
                          <span className={style['item-title']}><span className={style.nameItems}>ItemId: </span>{form.itemId}</span>
                          <span className={style['item-title']}><span className={style.nameItems}>Atualizado em: </span>{format(new Date(form.updatedAt), 'dd/MM/yyyy HH:mm', { locale: ptBR })}</span>
                          <div className={style.divStatus}>
                            <span className={`${form.status === 'published' ? style.published : style.unpublished}`}>
                              {form.status}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <div className={style.formExcluded}>
                        <ButtonRemoveForm
                          onClick={async () => {
                            try {
                              await ExcludedForm(form.id, ambient, clientId, allForm, setAllForm);
                              handleUpdatePage();
                            } catch (error) {
                              displayError("Erro ao remover e atualizar os formulários");
                              return error;
                            }
                          }}
                        />
                      </div> */}
                    </div>
                  ))}

              </div>

            </div>

          </>
        )}
      </div>
    </div>
  );
}