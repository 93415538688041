import { useEffect, useRef, useState } from 'react';
import { useAuthContext } from '../../hooks/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faUpload, faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';
// import csvTemplate from '../../../public/';
import Papa from 'papaparse';
import style from './style.module.scss';
import { displayError, displayWarning } from "../../utils/functions/messageToast";
import { tabulateTickets } from '../../utils/functions/functionsLoadDataForm';

export default function Tabulation() {
  const { authorization, user } = useAuthContext();
  const ambient = user?.ambient as string;
  const [loadingButton, setLoadingButton] = useState(false);
  const [tabId, setTabId] = useState('');
  const [fileName, setFileName] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [csvData, setCsvData] = useState<any[]>([]);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const useIsomorphicEffect = useEffect;
  const [cacheCleared, setCacheCleared] = useState(false);

  useIsomorphicEffect(() => {
    const executeClearCache = async () => {
      await clearCacheClient();
    };
    executeClearCache();

  }, []);

  const clearCacheClient = async () => {
    try {
      const cacheKeys = await caches.keys();

      if (cacheKeys.length > 0) {
        await Promise.all(cacheKeys.map(key => caches.delete(key)));
        setCacheCleared(true);

        // if (!window.location.search.includes('reloaded=true')) {
        //   window.location.replace(window.location.href + '?reloaded=true');
        // }
      }
    } catch (error: any) {
      return error
    }
  };

  const adjustHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'; // Reseta a altura
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [csvData]);

  const handleCSVUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name); // Atualiza o nome do arquivo quando o arquivo for selecionado
      Papa.parse(file, {
        header: true,
        delimiter: ";",
        transform: (value, column) => {
          return column ? value : undefined;
        },
        complete: (result: any) => {
          const cleanedData = result.data.map((row: any) =>
            Object.fromEntries(Object.entries(row).filter(([key]) => key))
          );
          setCsvData(cleanedData);
        },
        error: (error: any) => {
          displayError("Erro ao ler o arquivo CSV");
        },
      });
    }
  };

  const openFileSelector = () => {
    fileInputRef.current?.click();
  };

  const handleTabulation = async () => {
    if (!fileName) {
      displayWarning("Nenhum arquivo CSV foi carregado!");
      return;
    }
    setLoadingButton(true);
    await new Promise((resolve) => setTimeout(resolve, 3000));
    await tabulateTickets(tabId, ambient, authorization, csvData, setCsvData, setTabId);
    setLoadingButton(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setLoadingButton(false);
    setIsModalOpen(false); // Fecha o modal após a ação
  };

  return (
    <div>
      <Helmet>
        <title>Tech Forms - Tabulação em massa</title>
      </Helmet>

      <div className={style.container}>
        <div className={style.containerMain}>

          <div className={style.items}>
            <button className={style.buttonUploadCSV} onClick={openFileSelector}>
              <h4>Arquivo CSV</h4>
              <FontAwesomeIcon icon={faUpload} />
              <input
                type="file"
                accept=".csv"
                ref={fileInputRef}
                onChange={handleCSVUpload}
                style={{ display: 'none' }}
              />
            </button>

            <input
              className={style.inputTabId}
              placeholder="Insira a tabId de destino"
              type="text"
              value={tabId}
              onChange={(e) => setTabId(e.target.value)}
            />

            {fileName && (
              <div className={style.fileNameContainer}>
                <div className={style.fileNameDisplay}>
                  <span>{fileName}</span>
                  <button
                    className={style.removeFileButton}
                    onClick={() => {
                      setCsvData([]);
                      setTabId('');
                      setFileName(''); // Limpa o nome do arquivo ao limpar
                      if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className={style.items}>

            <button
              className={style.buttonSend}
              onClick={async () => {
                if (tabId === '') {
                  displayWarning("Insira a tabId de destino!");
                  return;
                }
                setIsModalOpen(true)
              }}
            >
              {!loadingButton ? (
                <a>Tabular</a>
              ) : (
                <FontAwesomeIcon icon={faSpinner} spin />
              )}

            </button>

            <button
              className={style.buttonClear}
              onClick={() => {
                setCsvData([]);
                setTabId('');
                setFileName(''); // Limpa o nome do arquivo ao limpar
                if (fileInputRef.current) {
                  fileInputRef.current.value = '';
                }
              }}
            >
              Limpar
            </button>

          </div>
        </div>

        <div className={style.fileNameContainer}>
          <div className={style.fileNameDisplay}>
            <a href="/tabulationTicket.csv" download="tabulationTicket.csv">
              <h4>Baixar modelo CSV < FontAwesomeIcon icon={faDownload} /></h4>

            </a>
          </div>
        </div>

        <h1 style={{ color: '#fff' }} className={style.divTextArea}>Errors</h1>
        <textarea
          value={JSON.stringify(csvData, null, 2)}
          className={style.jsonTextarea}
          placeholder=""
          readOnly
        />

        {isModalOpen && (
          <div className={style.modal}>
            <div className={style.modalContent}>
              <p>Confirma a tabulação em massa?</p>
              <div className={style.modalActions}>
                <button
                  className={style.modalButton}
                  onClick={handleTabulation}
                  disabled={loadingButton}
                >
                  {loadingButton ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Confirmar'}
                </button>
                <button
                  className={style.modalButton}
                  onClick={() => setIsModalOpen(false)} // Fecha o modal
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  );
}
