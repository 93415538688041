import { FieldProps, GroupProps } from "./functionsInterfaces";
import { getFormSideProps } from "./functionsLoadDataForm";

export const handleFormSubmit = (
  setExpandedPages: React.Dispatch<React.SetStateAction<boolean>>,
  setShowTopPageContainer: React.Dispatch<React.SetStateAction<boolean>>,
  setNamePage: React.Dispatch<React.SetStateAction<string>>,
  setPageId: React.Dispatch<React.SetStateAction<string>>,
  setIsData: React.Dispatch<React.SetStateAction<boolean>>,
  event: React.FormEvent<HTMLFormElement>,
  search: string,
  setFormId: React.Dispatch<React.SetStateAction<string>>,
  setNameForm: React.Dispatch<React.SetStateAction<string>>,
  setSkillIdForm: React.Dispatch<React.SetStateAction<string>>,
  setTabIdForm: React.Dispatch<React.SetStateAction<string>>,
  setItemId: React.Dispatch<React.SetStateAction<string>>,
  setGroups: React.Dispatch<React.SetStateAction<GroupProps[]>>,
  setFields: React.Dispatch<React.SetStateAction<FieldProps[]>>,
  setShowTopContainer: React.Dispatch<React.SetStateAction<boolean>>,
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>,
  setButtonPublish: React.Dispatch<React.SetStateAction<boolean>>,
  clientId: string,
  apiGetFormsAndLogin: any,
  setAliasFormResult: React.Dispatch<React.SetStateAction<string>>,
  setForm: React.Dispatch<React.SetStateAction<any>>,
  ambient: string,
  setSkillName: React.Dispatch<React.SetStateAction<string>>,
  authorization: string,
  setTabName: React.Dispatch<React.SetStateAction<string>>
) => {
  event.preventDefault();
  getFormSideProps(
    setExpandedPages,
    setShowTopPageContainer,
    setNamePage,
    setPageId,
    setIsData,
    search,
    setFormId,
    setNameForm,
    setSkillIdForm,
    setTabIdForm,
    setItemId,
    setGroups,
    setFields,
    setShowTopContainer,
    setButtonSave,
    setButtonPublish,
    clientId,
    apiGetFormsAndLogin,
    setAliasFormResult,
    setForm,
    ambient,
    setSkillName,
    authorization,
    setTabName
  );
};

export const handleInputAliasChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setAliasFormResult: React.Dispatch<React.SetStateAction<string>>
) => {
  setAliasFormResult(event.target.value);
};

export const handleInputNameChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setNameForm: React.Dispatch<React.SetStateAction<string>>
) => {
  setNameForm(event.target.value);
};

export const handleInputTabChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setTabIdForm: React.Dispatch<React.SetStateAction<string>>
) => {
  setTabIdForm(event.target.value);
};

export const handleInputItemIdChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setItemId: React.Dispatch<React.SetStateAction<string>>
) => {
  setItemId(event.target.value);
};

export const handleInputSkillChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setSkillIdForm: React.Dispatch<React.SetStateAction<string>>
) => {
  setSkillIdForm(event.target.value);
};

export const handleInputChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setSearch: React.Dispatch<React.SetStateAction<string>>
) => {
  setSearch(event.target.value);
};

export const handleInputIdChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setFormId: React.Dispatch<React.SetStateAction<string>>
) => {
  setFormId(event.target.value);
};
