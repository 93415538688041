import { FieldProps, GroupProps } from "./functionsInterfaces";
import { v4 as uuidv4 } from "uuid";
import { displayError, displayInfo } from "./messageToast";

// DUPLICA O GRUPO SELECIONADO
export const handleCopyGroup = (
  groups: GroupProps[],
  groupId: string,
  groupName: string,
  form: GroupProps[],
  setForm: React.Dispatch<React.SetStateAction<GroupProps[]>>,
  uuid: string,
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    // Função recursiva para encontrar o grupo a ser copiado e o grupo pai
    const findGroupAndParent = (
      groups: (GroupProps | FieldProps)[],
      groupId: string,
      uuid: string
    ): { group: GroupProps | null; parent: GroupProps | null } => {
      for (const group of groups) {
        if ("children" in group) {
          if (group.uuid === uuid || group.id === groupId) {
            return { group: group as GroupProps, parent: null };
          }
          const found = findGroupAndParent(group.children ?? [], groupId, uuid);
          if (found.group) {
            if (found.parent === null) {
              found.parent = group as GroupProps;
            }
            return found;
          }
        }
      }
      return { group: null, parent: null };
    };

    const { group: groupToCopy, parent: parentGroup } = findGroupAndParent(
      form,
      groupId,
      uuid
    );
    if (!groupToCopy) {
      console.error("Group not found");
      return;
    }

    const nameWithoutNumber = groupId.replace(/\d+$/, "");

    const sameNameGroups = (parentGroup ? parentGroup.children : form).filter(
      (group: any) => {
        const otherNameWithoutNumber = group.id.replace(/\d+$/, "");
        return otherNameWithoutNumber === nameWithoutNumber;
      }
    );

    let incrementedCounter = sameNameGroups.length;

    let newGroupId = `${nameWithoutNumber}${incrementedCounter}`;

    while (
      (parentGroup ? parentGroup.children : form).some(
        (group: any) => group.id === newGroupId
      )
    ) {
      incrementedCounter++;
      newGroupId = `${nameWithoutNumber}${incrementedCounter}`;
    }

    const copiedGroup: GroupProps = {
      ...groupToCopy,
      uuid: uuidv4(),
      id: newGroupId,
      name: groupName,
      children: groupToCopy.children.map((child: FieldProps | GroupProps) => ({
        ...child,
        uuid: uuidv4(), // Ensure each field/group has a unique UUID
      })),
    };

    const groupWithMaxNumberIndex = (
      parentGroup ? parentGroup.children : form
    ).reduce((acc: number, group: GroupProps, index: number) => {
      if (group.id.includes(nameWithoutNumber)) {
        acc = index;
      }
      return acc;
    }, 0);

    const newForm = [...form];
    const targetGroup = parentGroup ? parentGroup.children : newForm;
    targetGroup.splice(groupWithMaxNumberIndex + 1, 0, copiedGroup);
    setForm(newForm);
    setButtonSave(true);
    displayInfo("Grupo duplicado!");
  } catch (error: any) {
    displayError("Erro ao duplicar grupo!");
  }
};

// ADICIONA UM CAMPO PADRÃO NOVO SEM GRUPO
export const handleAddFieldOutsideGroup = (
  setForm: React.Dispatch<React.SetStateAction<any[]>>,
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const newField: FieldProps = {
    uuid: uuidv4(),
    id: "",
    name: "",
    isRequired: false,
    isKey: false,
    type: "field",
    fieldType: "long_text",
    settings: {
      placeholder: `Digite aqui`,
    },
  };
  setForm((prevForm) => [newField, ...prevForm]);
  setButtonSave(true);
  displayInfo("Campo padrão criado!");
};

// ADICIONA UM CAMPO DE ANEXO NOVO SEM GRUPO
export const handleAddAttachmentsFieldOutsideGroup = (
  setForm: React.Dispatch<React.SetStateAction<any[]>>,
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const newField: FieldProps = {
    uuid: uuidv4(),
    id: "",
    name: "",
    isRequired: false,
    isKey: false,
    type: "field",
    settings: {
      sizeLimit: { max: 10, enabled: true },
      allowedTypes: [
        "doc",
        "docx",
        "png",
        "jpg",
        "jpeg",
        "pdf",
        "xlsx",
        "xls",
        "eml",
        "msg",
        "txt",
        "xml",
        "xlt",
      ],
    },
    fieldType: "multiple_files",
  };
  setForm((prevForm) => [newField, ...prevForm]);
  setButtonSave(true);
  displayInfo("Campo de anexo criado!");
};

// ADICIONA UM GRUPO NOVO
export const handleAddGroup = (
  form: any,
  setForm: React.Dispatch<React.SetStateAction<any[]>>,
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const newGroup: GroupProps = {
    uuid: uuidv4(),
    id: "",
    name: "",
    type: "group",
    children: [],
  };
  setForm((prevForm) => [newGroup, ...prevForm]);
  setButtonSave(true);
  displayInfo("Grupo criado!");
};

// ADICIONA UM CAMPO PADRÃO NOVO NO GRUPO SELECIONADO
export const handleAddField = (
  groupId: string,
  expandedGroups: string[],
  form: any,
  setForm: React.Dispatch<React.SetStateAction<any[]>>,
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let newForm = [...form];

  const newField: FieldProps = {
    uuid: uuidv4(),
    id: "",
    name: "",
    isRequired: false,
    isKey: false,
    type: "field",
    fieldType: "long_text",
    settings: {
      placeholder: `Digite aqui`,
    },
  };

  const addFieldToGroup = (groups: any[], groupId: string): boolean => {
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];

      if (group.uuid === groupId) {
        expandedGroups.push(group.id);

        if ("children" in group) {
          group.children.push(newField);
          newForm = [...form];
        } else {
          group.children = [newField];
          newForm = [...form];
        }
        return true;
      }

      if (group.children) {
        const found = addFieldToGroup(group.children, groupId);
        if (found) return true;
      }
    }
    return false;
  };

  const groupFound = addFieldToGroup([...form], groupId);

  if (groupFound) {
    setForm(newForm);
    setButtonSave(true);
    displayInfo("Campo padrão criado!");
  } else {
    console.error(`Group with id ${groupId} not found`);
  }
};

// ADICIONA UM CAMPO DE ANEXO NOVO NO GRUPO SELECIONADO
export const handleAddFieldAttachments = (
  groupId: string,
  expandedGroups: string[],
  form: any,
  setForm: React.Dispatch<React.SetStateAction<any[]>>,
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
) => {
  expandedGroups.push(groupId as string);
  const newField: FieldProps = {
    uuid: uuidv4(),
    id: "",
    name: "",
    isRequired: false,
    isKey: false,
    type: "field",
    settings: {
      sizeLimit: { max: 10, enabled: true },
      allowedTypes: [
        "doc",
        "docx",
        "png",
        "jpg",
        "jpeg",
        "pdf",
        "xlsx",
        "xls",
        "eml",
        "msg",
        "txt",
        "xml",
        "xlt",
      ],
    },
    fieldType: "multiple_files",
  };

  // Função recursiva para encontrar e adicionar o campo ao grupo correto
  const addFieldToGroup = (groups: any[], groupId: string): boolean => {
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      if (group.id === groupId) {
        if ("children" in group) {
          group.children.push(newField);
        } else {
          group.children = [newField];
        }
        return true;
      }
      if (group.children) {
        const found = addFieldToGroup(group.children, groupId);
        if (found) return true;
      }
    }
    return false;
  };

  const newForm = [...form];
  const groupFound = addFieldToGroup(newForm, groupId);

  if (groupFound) {
    setForm(newForm);
    setButtonSave(true);
    displayInfo("Campo de anexo criado!");
  } else {
    console.error(`Group with id ${groupId} not found`);
  }
};

// REMOVE O GRUPO SELECIONADO
export const handleRemoveGroup = (
  groupId: string,
  form: any,
  setForm: React.Dispatch<React.SetStateAction<any[]>>,
  uuid: string,
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const updatedForm = form.filter(
    (item: any) => item.uuid !== uuid || item.id !== groupId
  );
  setButtonSave(true);
  setForm(updatedForm);
};

// REMOVE O CAMPO SELECIONADO DE DENTRO DO GRUPO
export const handleRemoveFieldInGroup = (
  groupId: string | undefined,
  fieldId: string,
  form: any,
  setForm: React.Dispatch<React.SetStateAction<any[]>>,
  uuid: string,
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const removeFieldRecursively = (items: any[]): any[] => {
    return items
      .map((item: any) => {
        if (item.uuid === groupId || item.id === fieldId) {
          if (item.children) {
            return {
              ...item,
              children: removeFieldRecursively(
                item.children.filter(
                  (field: any) => field.uuid !== uuid || field.id !== fieldId
                )
              ),
            };
          }
        } else if (item.children) {
          return {
            ...item,
            children: removeFieldRecursively(item.children),
          };
        }
        return item;
      })
      .filter((field) => field.uuid !== fieldId);
  };

  const newItems = removeFieldRecursively(form);
  setForm(newItems);
  setButtonSave(true);
};

// REMOVE O CAMPO SELECIONADO DE FORA DO GRUPO
export const handleRemoveField = (
  groupId: string | undefined,
  fieldId: string,
  form: any,
  setForm: React.Dispatch<React.SetStateAction<any[]>>,
  uuid: string,
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const newItems = form.filter((item: any) => item.uuid !== fieldId);
  setForm(newItems);
  setButtonSave(true);
};

// ADICIONA O NOVO CAMPO NO GRUPO GERAL
export const handleAddItem = (
  newItem: GroupProps | FieldProps,
  allItems: (GroupProps | FieldProps)[],
  setAllItems: React.Dispatch<
    React.SetStateAction<(GroupProps | FieldProps)[]>
  >,
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setAllItems([newItem, ...allItems]);
  setButtonSave(true);
  displayInfo("Campo criado!");
};

// ADICIONA UM CAMPO PERSONAL DETAIL NOVO SEM GRUPO
export const handleAddPersonalDetailFieldOutsideGroup = (
  form: any,
  setForm: React.Dispatch<React.SetStateAction<any[]>>,
  fieldAdd: string,
  setAddFieldPersonalDetails: React.Dispatch<React.SetStateAction<string>>,
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const findFieldInForm = (
    form: (GroupProps | FieldProps)[],
    fieldAdd: string
  ): boolean => {
    return form.some((item: GroupProps | FieldProps) => {
      if ("children" in item) {
        if (item.children && findFieldInForm(item.children, fieldAdd)) {
          return true;
        }
      } else if (item.id === fieldAdd) {
        return true;
      }
      return false;
    });
  };
  const fieldExists = findFieldInForm(form, fieldAdd);

  if (fieldExists) {
    displayError("Campo já existe!");
    setAddFieldPersonalDetails("");
    return;
  }

  if (fieldAdd === "customerSocialNumber") {
    const newField: FieldProps = {
      uuid: uuidv4(),
      id: "customerSocialNumber",
      name: "CPF do cliente",
      type: "field",
      settings: {
        detail: "customerSocialNumber",
        placeholder: "Insira o CPF do cliente",
      },
      fieldType: "personal_details",
    };
    setForm((prevForm) => [newField, ...prevForm]);
    setAddFieldPersonalDetails("");
    setButtonSave(true);
    displayInfo("Campo de documento criado!");
  } else if (fieldAdd === "customerPhoneNumber") {
    const newField: FieldProps = {
      uuid: uuidv4(),
      id: "customerPhoneNumber",
      name: "Telefone do cliente",
      type: "field",
      settings: {
        detail: "customerPhoneNumber",
        placeholder: "Insira o telefone do cliente.",
      },
      fieldType: "personal_details",
    };
    setForm((prevForm) => [newField, ...prevForm]);
    setAddFieldPersonalDetails("");
    setButtonSave(true);
    displayInfo("Campo de telefone criado!");
  } else if (fieldAdd === "customerEmail") {
    const newField: FieldProps = {
      uuid: uuidv4(),
      id: "customerEmail",
      name: "Email",
      type: "field",
      settings: {
        detail: "customerEmail",
        placeholder: "Insira seu Email",
        autofillParam: "mail",
      },
      fieldType: "personal_details",
    };
    setForm((prevForm) => [newField, ...prevForm]);
    setAddFieldPersonalDetails("");
    setButtonSave(true);
    displayInfo("Campo de email criado!");
  } else {
    const newField: FieldProps = {
      uuid: uuidv4(),
      id: "customerName",
      name: "Nome do cliente",
      type: "field",
      settings: {
        detail: "customerName",
        placeholder: "Insira o Nome do cliente",
      },
      fieldType: "personal_details",
    };
    setForm((prevForm) => [newField, ...prevForm]);
    setAddFieldPersonalDetails("");
    setButtonSave(true);
    displayInfo("Campo de nome criado!");
  }
};

// ADICIONA UM CAMPO PERSONAL DETAIL NOVO NO GRUPO SELECIONADO
export const handleAddPersonalDetailField = (
  groupId: string,
  groups: GroupProps[],
  expandedGroups: string[],
  form: GroupProps[],
  setForm: React.Dispatch<React.SetStateAction<GroupProps[]>>,
  fieldAdd: string,
  setAddFieldPersonalDetails: React.Dispatch<React.SetStateAction<string>>,
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
) => {
  expandedGroups.push(groupId as string);

  let newFieldPersonalDetail: FieldProps;

  const findFieldInForm = (
    form: (GroupProps | FieldProps)[],
    fieldAdd: string
  ): boolean => {
    return form.some((item: GroupProps | FieldProps) => {
      if ("children" in item) {
        if (item.children && findFieldInForm(item.children, fieldAdd)) {
          return true;
        }
      } else if (item.id === fieldAdd) {
        return true;
      }
      return false;
    });
  };
  const fieldExists = findFieldInForm(form, fieldAdd);

  if (fieldExists) {
    displayError("Campo já existe!");
    setAddFieldPersonalDetails("");
    return;
  }

  if (fieldAdd === "customerSocialNumber") {
    newFieldPersonalDetail = {
      uuid: uuidv4(),
      id: "customerSocialNumber",
      name: "CPF do cliente",
      type: "field",
      settings: {
        detail: "customerSocialNumber",
        placeholder: "Insira o CPF do cliente",
      },
      fieldType: "personal_details",
    };
    setButtonSave(true);
    displayInfo("Campo de documento criado!");
  } else if (fieldAdd === "customerPhoneNumber") {
    newFieldPersonalDetail = {
      uuid: uuidv4(),
      id: "customerPhoneNumber",
      name: "Telefone do cliente",
      type: "field",
      settings: {
        detail: "customerPhoneNumber",
        placeholder: "Insira o telefone do cliente.",
      },
      fieldType: "personal_details",
    };
    setButtonSave(true);
    displayInfo("Campo de telefone criado!");
  } else if (fieldAdd === "customerEmail") {
    newFieldPersonalDetail = {
      uuid: uuidv4(),
      id: "customerEmail",
      name: "Email",
      type: "field",
      settings: {
        detail: "customerEmail",
        placeholder: "Insira seu Email",
        autofillParam: "mail",
      },
      fieldType: "personal_details",
    };
    setButtonSave(true);
    displayInfo("Campo de email criado!");
  } else {
    newFieldPersonalDetail = {
      uuid: uuidv4(),
      id: "customerName",
      name: "Nome do cliente",
      type: "field",
      settings: {
        detail: "customerName",
        placeholder: "Insira o Nome do cliente",
      },
      fieldType: "personal_details",
    };
    setButtonSave(true);
    displayInfo("Campo de nome criado!");
  }

  setAddFieldPersonalDetails("");

  const findGroupAndAddField = (
    groups: (GroupProps | FieldProps)[]
  ): boolean => {
    for (const group of groups) {
      if ("children" in group) {
        if (group.id === groupId) {
          if (group.children) {
            group.children.push(newFieldPersonalDetail);
          } else {
            group.children = [newFieldPersonalDetail];
          }
          return true;
        }
        if (findGroupAndAddField(group.children ?? [])) {
          return true;
        }
      }
    }
    return false;
  };

  const newForm = [...form];
  if (findGroupAndAddField(newForm)) {
    setForm(newForm);
  } else {
    displayError("Grupo não encontrado!");
  }
};
