import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import style from './styles.module.scss'

import {
  faAngleDown,
  faAngleUp,
  faFileArrowUp,
  faFilePowerpoint,
  faLayerGroup
} from '@fortawesome/free-solid-svg-icons'

import {
  FormGroupsProps,
} from '../../utils/functions/functionsInterfaces'

import { SelectFieldPersonalDetails } from '../ui/selects'
import { Draggable, Droppable } from '@hello-pangea/dnd'
import { InputsInfosGroups } from '../ui/inputs'
import { ButtonAddField, ButtonAddFieldAttachments, ButtonCopyGroup, ButtonRemoveGroup } from '../ui/buttons'
import { Field } from '../fields'
import FormFields from '../FormFields'

export default function FormGroups({
  form,
  setForm,
  groups,
  expandedGroups,
  setExpandedGroups,
  handleCopyGroup,
  handleRemoveGroup,
  handleAddField,
  handleAddFieldAttachments,
  handleAddPersonalDetailField,
  handleRemoveField,
  handleRemoveFieldInGroup,
  toggleGroupVisibility,
  setAddFieldPersonalDetails,
  addFieldPersonalDetails,
  setButtonSave,
  authorization,
  clientId,
  ambient,
  formId,
  item,
  index,
  outsideTheGroup,
  structureForm
}: FormGroupsProps) {


  return (
    <Droppable
      droppableId={item.uuid || ''}
      key={item.uuid}
      type="group"
    >
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps} >

          {item.children.map((fieldInGroup: any, indexInGroup: number) => (
            <Draggable
              draggableId={fieldInGroup.uuid}
              index={indexInGroup}
              key={fieldInGroup.uuid}
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >

                  {fieldInGroup.type === 'group' ? (
                    <div key={indexInGroup} className={style.groupContainer}>

                      <div className={style.headerGroup}>

                        <div className={style.iconIdentifier}>
                          <FontAwesomeIcon
                            icon={faLayerGroup}
                            style={{ color: '#FFD43B' }}
                          />
                        </div>

                        <InputsInfosGroups
                          placeholder='Digite o ID do grupo'
                          value={fieldInGroup.id}
                          onChange={(e) => {
                            const newId = e.target.value
                            const updatedForm = form.map((group, idx) => {
                              if (indexInGroup === idx) {
                                return { ...group, id: newId }
                              }
                              return group
                            })
                            setForm(updatedForm)
                          }}
                          onBlur={() => {
                            setButtonSave(true)
                          }}
                        />

                        <InputsInfosGroups
                          placeholder='Digite o nome do grupo'
                          value={fieldInGroup.name}
                          onChange={(e) => {
                            const newName = e.target.value
                            const updatedForm = form.map((group, idx) => {
                              if (indexInGroup === idx) {
                                return { ...group, name: newName }
                              }
                              return group
                            })
                            setForm(updatedForm)
                          }}
                          onBlur={() => {
                            setButtonSave(true)
                          }}
                        />

                        <ButtonAddField
                          onClick={() => {
                            handleAddField(
                              fieldInGroup.uuid,
                              expandedGroups,
                              form,
                              setForm,
                              setButtonSave
                            )
                            setExpandedGroups([...expandedGroups, fieldInGroup.uuid])
                          }
                          }
                        />

                        <ButtonAddFieldAttachments
                          onClick={() => {
                            handleAddFieldAttachments(
                              fieldInGroup.id,
                              expandedGroups,
                              form,
                              setForm,
                              setButtonSave
                            )
                            setExpandedGroups([...expandedGroups, fieldInGroup.uuid])
                          }}
                        />

                        <ButtonCopyGroup
                          onClick={() =>
                            handleCopyGroup(
                              groups,
                              fieldInGroup.id,
                              fieldInGroup.name,
                              form,
                              setForm,
                              fieldInGroup.uuid || '',
                              setButtonSave
                            )
                          }
                        />

                        <ButtonRemoveGroup
                          onClick={() =>
                            handleRemoveGroup(
                              fieldInGroup.id,
                              form,
                              setForm,
                              fieldInGroup.uuid,
                              setButtonSave
                            )
                          }
                        />

                        <SelectFieldPersonalDetails
                          value={addFieldPersonalDetails}
                          onChange={(
                            event: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            const newValue = event.target.value
                            setAddFieldPersonalDetails(newValue)
                            handleAddPersonalDetailField(
                              fieldInGroup.id,
                              groups,
                              expandedGroups,
                              form,
                              setForm,
                              newValue,
                              setAddFieldPersonalDetails,
                              setButtonSave
                            )
                            setExpandedGroups([...expandedGroups, fieldInGroup.uuid])
                          }}
                          title='Adicionar Campos Customizados'
                        />

                        <button
                          type='button'
                          onClick={() =>
                            toggleGroupVisibility(
                              fieldInGroup.uuid,
                              expandedGroups,
                              setExpandedGroups
                            )
                          }
                          className={`${style.buttonToggleGroupVisibility} ${style.buttonIcon}`}
                          title='Esconder Campos'
                        >
                          {expandedGroups.includes(fieldInGroup.uuid) ? (
                            <FontAwesomeIcon
                              icon={faAngleUp}
                              className={style.faIcon}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              className={style.faIcon}
                            />
                          )}
                        </button>

                      </div>

                      <FormFields
                        form={form}
                        setForm={setForm}
                        groups={groups}
                        handleRemoveField={handleRemoveField}
                        handleRemoveFieldInGroup={handleRemoveFieldInGroup}
                        setButtonSave={setButtonSave}
                        setAddFieldPersonalDetails={setAddFieldPersonalDetails}
                        authorization={authorization}
                        clientId={clientId}
                        ambient={ambient}
                        formId={formId}
                        item={fieldInGroup}
                        index={indexInGroup}
                        outsideTheGroup={outsideTheGroup}
                        expandedGroups={expandedGroups}
                        setExpandedGroups={setExpandedGroups}
                        addFieldPersonalDetails={addFieldPersonalDetails}
                      />

                    </div>
                  ) : fieldInGroup.fieldType === 'multiple_files' ? (
                    /* campo de anexo fora do grupo */
                    <div key={fieldInGroup.uuid} className={fieldInGroup.fieldType === 'personal_details' ? style.fieldContainerPersonalDetails : style.fieldContainer
                    }>
                      <div className={style.iconIdentifier}>
                        <FontAwesomeIcon
                          icon={faFileArrowUp}
                          style={{ color: '#0000fa' }}
                        />
                      </div>

                      <Field
                        fieldGroupInGroup={fieldInGroup}
                        indexFieldGroupInGroup={indexInGroup}
                        index={index}
                        field={fieldInGroup}
                        handleRemoveFieldInGroup={handleRemoveFieldInGroup}
                        handleRemoveField={handleRemoveField}
                        form={form}
                        setForm={setForm}
                        outsideTheGroup={false}
                        isPersonalDetail={false}
                        setButtonSave={setButtonSave}
                        authorization={authorization}
                        clientId={clientId}
                        ambient={ambient}
                        formId={formId}
                        groupId={item.id}
                      />

                    </div>
                  ) : fieldInGroup.fieldType === 'personal_details' ? (
                    /* campo personal details fora do grupo */
                    <div key={indexInGroup} className={fieldInGroup.fieldType === 'personal_details' ? style.fieldContainerPersonalDetails : style.fieldContainer
                    }>
                      <div className={style.iconIdentifier}>
                        <FontAwesomeIcon
                          icon={faFilePowerpoint}
                          style={{ color: '#63E6BE' }}
                        />
                      </div>

                      <Field
                        fieldGroupInGroup={fieldInGroup}
                        indexFieldGroupInGroup={indexInGroup}
                        index={index}
                        field={fieldInGroup}
                        handleRemoveFieldInGroup={handleRemoveFieldInGroup}
                        handleRemoveField={handleRemoveField}
                        form={form}
                        setForm={setForm}
                        outsideTheGroup={false}
                        isPersonalDetail={true}
                        setButtonSave={setButtonSave}
                        authorization={authorization}
                        clientId={clientId}
                        ambient={ambient}
                        formId={formId}
                        groupId={item.id}
                      />

                    </div>
                  ) : (
                    /* campo padrao fora do grupo */
                    <div key={indexInGroup} className={fieldInGroup.fieldType === 'personal_details' ? style.fieldContainerPersonalDetails : style.fieldContainer
                    }>

                      <Field
                        fieldGroupInGroup={fieldInGroup}
                        indexFieldGroupInGroup={indexInGroup}
                        index={index}
                        field={fieldInGroup}
                        handleRemoveFieldInGroup={handleRemoveFieldInGroup}
                        handleRemoveField={handleRemoveField}
                        form={form}
                        setForm={setForm}
                        outsideTheGroup={false}
                        isPersonalDetail={false}
                        setButtonSave={setButtonSave}
                        authorization={authorization}
                        clientId={clientId}
                        ambient={ambient}
                        formId={formId}
                        groupId={item.id}
                      />

                    </div>
                  )}

                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )

}