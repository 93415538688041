import { ButtonHTMLAttributes, ReactNode } from 'react'
import styles from './styles.module.scss'
import { faCopy, faFile, faObjectGroup, faPlus, faSpinner, faTrash, faTrashArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
  buttonPublish?: boolean
  buttonSave?: boolean
  children?: ReactNode
  loadingButtonCreate?: boolean
}

export function ButtonAddGroup({ ...rest }) {
  return (
    <button type='button' title='Adicionar Grupo' className={styles.buttonCommand} {...rest}>
      <FontAwesomeIcon icon={faPlus} />
      <FontAwesomeIcon icon={faObjectGroup} />
    </button>
  )
}

export function ButtonAddFieldOutsideGroup({ ...rest }) {
  return (
    <button type='button' title='Adicionar campo padrão Fora de Grupo' className={styles.buttonCommand} {...rest}>
      <FontAwesomeIcon icon={faPlus} />
      <FontAwesomeIcon icon={faPlus} />
    </button>
  )
}

export function ButtonAddAttachmentsFieldOutsideGroup({ ...rest }) {
  return (
    <button type='button' title='Adicionar campo de anexo Fora de Grupo' className={styles.buttonCommand} {...rest}>
      <FontAwesomeIcon icon={faPlus} />
      <FontAwesomeIcon icon={faFile} />
    </button>
  )
}

export function ButtonGenerateJson({ ...rest }) {
  return (
    <button type='button' title='Gerar template do form' className={styles.buttonCommand} {...rest}>
      GERAR JSON
    </button>
  )
}

export function ButtonSaveForm({ loading, buttonSave, ...rest }: ButtonProps) {
  return (
    <button title='Salvar Formulário' className={`${styles.buttonCommandSave} ${!buttonSave ? styles.disabled : ''
      }`} disabled={!buttonSave} {...rest}>
      {loading ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <a className={styles.buttonText}>Salvar</a>
      )}
    </button>
  )
}

export function ButtonPublishForm({ loading, buttonPublish, ...rest }: ButtonProps) {
  return (
    <button title='Publicar Formulário' disabled={!buttonPublish} className={`${styles.buttonCommandPublish} ${!buttonPublish ? styles.disabled : ''
      }`} {...rest}>
      {loading ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <a className={styles.buttonText}>Publicar</a>
      )}
    </button>
  )
}

export function ButtonGeneratePayload({ loading, ...rest }: ButtonProps) {
  return (
    <button title='Gerar payload de abertura de ticket' className={styles.buttonCommandPayload} disabled={loading} {...rest}>
      {loading ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <a className={styles.buttonText}>Gerar Payload</a>
      )}
    </button>
  )
}

export function ButtonLogin({ loading, children, ...rest }: ButtonProps) {
  return (
    <button className={styles.buttonLogin} disabled={loading} {...rest}>
      {loading ? (
        <FontAwesomeIcon color='#FFF' icon={faSpinner} spin />
      ) : (
        <a className={styles.buttonText}>Login</a>
      )}
    </button>
  )
}

export function ButtonSearchForm({ ...rest }) {
  return (
    <button type='button' title='Buscar Formulário' className={styles.buttonSearchClear} {...rest}>
      Buscar
    </button>
  )
}

export function ButtonClear({ ...rest }) {
  return (
    <button type='button' title='Limpar Busca' className={styles.buttonSearchClear} {...rest}>
      Limpar
    </button>
  )
}

export function ButtonAddFieldAttachments({ ...rest }) {
  return (
    <button type='button' title='Adicionar campo de anexo no grupo' className={styles.buttonGroups} {...rest}>
      {/* <FontAwesomeIcon icon={faPlus} /> */}
      <FontAwesomeIcon icon={faFile} />
    </button>
  )
}

export function ButtonAddField({ ...rest }) {
  return (
    <button type='button' title='Adicionar campo padrão no grupo' className={styles.buttonGroups} {...rest}>
      {/* <FontAwesomeIcon icon={faPlus} /> */}
      <FontAwesomeIcon icon={faPlus} />
    </button>
  )
}

export function ButtonCopyGroup({ ...rest }) {
  return (
    <button type='button' title='Duplicar Grupo' className={styles.buttonGroups} {...rest}>
      <FontAwesomeIcon icon={faCopy} />
    </button>
  )
}

export function ButtonRemoveGroup({ ...rest }) {
  return (
    <button type='button' title='Remover Grupo' className={styles.removeButton} {...rest}>
      <FontAwesomeIcon icon={faTrash} />
    </button>
  )
}

export function ButtonGetPathField({ ...rest }) {
  return (
    <button type='button' title='Copiar caminho do campo' className={styles.buttonPath} {...rest}>
      <FontAwesomeIcon icon={faCopy} />
    </button>
  )
}

export function ButtonCreateForm({ loadingButtonCreate, ...rest }: ButtonProps) {
  return (
    <button title='Criar Formulário' className={styles.buttonCommandCreateForm} {...rest}>
      {loadingButtonCreate ? (
        <FontAwesomeIcon icon={faSpinner} />
      ) : (
        <a className={styles.buttonText}>Criar Formulario</a>
      )}
    </button>
  )
}

export function ButtonCreateFormPayload({ loadingButtonCreate, ...rest }: ButtonProps) {
  return (
    <button title='Criar Formulário' className={styles.buttonCommandCreateFormPayload} {...rest}>
      {loadingButtonCreate ? (
        <FontAwesomeIcon icon={faSpinner} />
      ) : (
        <a className={styles.buttonText}>Criar Formulario</a>
      )}
    </button>
  )
}

export function ButtonRemoveForm({ ...rest }) {
  return (
    <button type='button' title='Remover Formulário' className={styles.ButtonRemoveForm} {...rest}>
      <FontAwesomeIcon icon={faTrash} size="2xl" />
    </button>
  )
}

export function ButtonRestoredForm({ ...rest }) {
  return (
    <button type='button' title='Restaurar Formulário' className={styles.ButtonRestoredForm} {...rest}>
      <FontAwesomeIcon icon={faTrashArrowUp} size="2xl" />
    </button>
  )
}