import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Helmet } from 'react-helmet-async';
import style from './style.module.scss';
import { getFormByIdItemIdFormList } from '../../utils/functions/functionsLoadDataForm';
import { RestoredForm, getAllFormsExcludeds } from '../../utils/functions/functionsForms';
import { ButtonRestoredForm } from '../../components/ui/buttons';

export default function ListFormsExcluded() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const ambient = user?.ambient as string;
  const clientId = user?.clientId as string;
  const [loadingPage, setLoadingPage] = useState(false);
  const [formsExcluded, setFormsExcluded] = useState<string[]>([]);

  const useIsomorphicEffect = useEffect;
  const [cacheCleared, setCacheCleared] = useState(false);

  useEffect(() => {
    getAllFormsExcludeds(setFormsExcluded);
    getFormByIdItemIdFormList(clientId, formsExcluded, ambient, setFormsExcluded, setLoadingPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, ambient, setFormsExcluded]);

  useIsomorphicEffect(() => {
    const executeClearCache = async () => {
      await clearCacheClient();
    };
    executeClearCache();
  }, []);

  const clearCacheClient = async () => {
    try {
      const cacheKeys = await caches.keys();

      if (cacheKeys.length > 0) {
        await Promise.all(cacheKeys.map(key => caches.delete(key)));
        setCacheCleared(true);

        // if (!window.location.search.includes('reloaded=true')) {
        //   window.location.replace(window.location.href + '?reloaded=true');
        // }
      }
    } catch (error: any) {
      return error
    }
  };

  const handleFormClick = (formId: string) => {
    navigate(`/section/updateForm?clientId=${clientId}&formId=${formId}`, { state: { formId } });
  };

  const handleUpdatePage = () => {
    getAllFormsExcludeds(setFormsExcluded);
  }

  return (
    <div>
      <Helmet>
        <title>Tech Forms - Listagem dos Formulários</title>
      </Helmet>

      <div className={style.container}>
        {loadingPage ? (
          <div className={style.spinnerContainer}>
            <FontAwesomeIcon icon={faSpinner} size="2x" style={{ color: "#ffffff" }} spin />
          </div>
        ) : (
          <>
            <div className={style.main}>

              <div className={style.divForm}>

                {formsExcluded
                  .map((form: any) => (
                    <div className={style.divFormExcluded}>
                      <div key={form.id} onClick={() => { handleFormClick(form.id); }} className={style.form}>
                        <div className={style.formRow}>
                          <span className={style['item-title']}><span className={style.nameItems}>Id: </span>{form.id}</span>
                          <span className={style['item-title']}><span className={style.nameItems}>Nome: </span>{form.name}</span>
                          <span className={style['item-title']}><span className={style.nameItems}>Criado em: </span>{format(new Date(form.createdAt), 'dd/MM/yyyy HH:mm', { locale: ptBR })}</span>
                        </div>
                        <div className={style.formRow}>
                          <span className={style['item-title']}><span className={style.nameItems}>ItemId: </span>{form.itemId}</span>
                          <span className={style['item-title']}><span className={style.nameItems}>Atualizado em: </span>{format(new Date(form.updatedAt), 'dd/MM/yyyy HH:mm', { locale: ptBR })}</span>
                          <div className={style.divStatus}>
                            <span className={`${form.status === 'published' ? style.published : style.unpublished}`}>
                              {form.status}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={style.formExcluded}>
                        <ButtonRestoredForm
                          onClick={() => {
                            RestoredForm(form.id, setFormsExcluded);
                            handleUpdatePage();
                          }}
                        />
                      </div>
                    </div>
                  ))}

                {!formsExcluded &&
                  <div className={style.divFormExcluded}>Nenhum formulário removido</div>
                }

              </div>

            </div>

          </>
        )}
      </div>
    </div>
  );
}