import { useEffect, useState } from 'react';
import { useAuthContext } from '../../hooks/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';
import style from './style.module.scss';
import { displaySuccess, displayWarning } from "../../utils/functions/messageToast";
import { createSkills } from '../../utils/functions/functionsLoadDataForm';

export default function CreateSkills() {
  const { authorization, user } = useAuthContext();
  const ambient = user?.ambient as string;
  const [loadingButton, setLoadingButton] = useState(false);
  const [namesData, setNamesData] = useState<any[]>([]);
  const [textAreaValue, setTextAreaValue] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const useIsomorphicEffect = useEffect;
  const [cacheCleared, setCacheCleared] = useState(false);

  useIsomorphicEffect(() => {
    const executeClearCache = async () => {
      await clearCacheClient();
    };
    executeClearCache();

  }, []);

  const clearCacheClient = async () => {
    try {
      const cacheKeys = await caches.keys();

      if (cacheKeys.length > 0) {
        await Promise.all(cacheKeys.map(key => caches.delete(key)));
        setCacheCleared(true);

        // if (!window.location.search.includes('reloaded=true')) {
        //   window.location.replace(window.location.href + '?reloaded=true');
        // }
      }
    } catch (error: any) {
      return error
    }
  };

  const handleTextAreaValue = async (value: string) => {
    const lines = value.split('\n').map(line => ({ name: line }));
    setNamesData(lines);
  }

  const handleCreateSkills = async () => {
    setLoadingButton(true);
    await createSkills(ambient, authorization, namesData, setTextAreaValue);
    setLoadingButton(false);
    setIsModalOpen(false); // Fecha o modal após a ação
  };

  return (
    <div>
      <Helmet>
        <title>Tech Forms - Criar habilidades em massa</title>
      </Helmet>

      <div className={style.container}>
        <div className={style.containerMain}>

          <textarea
            value={textAreaValue}
            className={style.jsonTextarea}
            placeholder=""
            onChange={(e) => {
              setTextAreaValue(e.target.value);
              handleTextAreaValue(e.target.value);
            }}
          />

          <div className={style.items}>
            <button
              className={style.buttonCreate}
              type="button"
              disabled={loadingButton}
              onClick={() => {
                if (textAreaValue === '') {
                  displayWarning("Adicione as habilidades no campo de texto!");
                  return;
                }
                setIsModalOpen(true)
              }}
            >
              {loadingButton ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Criar habilidades'}
            </button>

            <button
              className={style.buttonCreate}
              type="button"
              onClick={() => {
                if (textAreaValue === '') {
                  displayWarning("Nenhuma informação para copiar!");
                  return;
                }
                navigator.clipboard.writeText(textAreaValue);
                displaySuccess("Retorno copiado para a área de transferência!");
              }}
            >
              Copiar valor do retorno para area de transferencia
            </button>
          </div>

          {isModalOpen && (
            <div className={style.modal}>
              <div className={style.modalContent}>
                <p>Confirma a criação das habilidades?</p>
                <div className={style.modalActions}>
                  <button
                    className={style.modalButton}
                    onClick={handleCreateSkills}
                    disabled={loadingButton}
                  >
                    {loadingButton ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Confirmar'}
                  </button>
                  <button
                    className={style.modalButton}
                    onClick={() => setIsModalOpen(false)} // Fecha o modal
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

