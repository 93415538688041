import React from 'react'
import style from './styles.module.scss'
import { FieldProps, FormFieldsPropsField } from '../../utils/functions/functionsInterfaces'
import { InputsInfosFields } from '../../components/ui/inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ButtonGetPathField } from '../ui/buttons'
import { getPathField } from '../../utils/functions/functionsLoadDataForm'
import { generateJSON } from '../../utils/functions/functionGenerateJson'

export function Field({
  fieldGroupInGroup,
  indexFieldGroupInGroup,
  index,
  field,
  handleRemoveFieldInGroup,
  handleRemoveField,
  form,
  setForm,
  outsideTheGroup,
  isPersonalDetail,
  setButtonSave,
  authorization,
  clientId,
  ambient,
  formId,
  groupId,
}: FormFieldsPropsField) {

  return (

    <div className={style.field}>

      <InputsInfosFields
        placeholder='Digite o ID do campo'
        value={fieldGroupInGroup.id}
        onChange={(e) => {
          const newForm = form.map((group, groupIndex) => {
            if (index === groupIndex) {
              if (group.children) {
                return {
                  ...group,
                  children: group.children.map((f: FieldProps, fieldIndex: number) => {
                    if (indexFieldGroupInGroup === fieldIndex) {
                      return {
                        ...f,
                        id: e.target.value,
                      };
                    }
                    return f;
                  }),
                };
              } else {
                return {
                  ...group,
                  id: e.target.value,
                };
              }
            }

            return group;
          });
          setForm(newForm);
        }}
        onBlur={() => {
          setButtonSave(true)
        }}
      />

      <InputsInfosFields
        placeholder='Digite o nome do campo'
        value={fieldGroupInGroup.name}
        onChange={(e) => {
          const newForm = form.map((group, groupIndex) => {
            if (index === groupIndex) {
              if (group.children) {
                return {
                  ...group,
                  children: group.children.map((f: FieldProps, fieldIndex: number) => {
                    if (indexFieldGroupInGroup === fieldIndex) {
                      return {
                        ...f,
                        name: e.target.value,
                      };
                    }
                    return f;
                  }),
                };
              } else {
                return {
                  ...group,
                  name: e.target.value,
                };
              }
            }
            return group;
          });
          setForm(newForm);
        }}
        onBlur={() => {
          setButtonSave(true)
        }}
      />

      <div className={style.checkboxContainer}>

        <div className={style.checkboxItem}>
          <h1 title='Campo obrigatório'>*</h1>

          <input
            type='checkbox'
            checked={field.isRequired || false}
            onChange={(e) => {
              const newForm = form.map((group, groupIndex) => {
                if (index === groupIndex) {
                  if (group.children) {
                    return {
                      ...group,
                      children: group.children.map((f: FieldProps, fieldIndex: number) => {
                        if (indexFieldGroupInGroup === fieldIndex) {
                          return {
                            ...f,
                            isRequired: e.target.checked,
                          };
                        }
                        return f;
                      }),
                    };
                  } else {
                    return {
                      ...group,
                      isRequired: e.target.checked,
                    };
                  }
                }
                return group;
              });
              setForm(newForm);
            }}
            onBlur={() => {
              setButtonSave(true)
            }}
          />
        </div>

        {!isPersonalDetail && (
          <div className={style.checkboxItem}>
            <h1 title='Campo para filtro'>
              <FontAwesomeIcon icon={faMagnifyingGlass} size="2xs" />
            </h1>

            <input
              type='checkbox'
              checked={fieldGroupInGroup.isKey || false}
              onChange={(e) => {
                const newForm = form.map((group, groupIndex) => {
                  if (index === groupIndex) {
                    if (group.children) {
                      return {
                        ...group,
                        children: group.children.map((f: FieldProps, fieldIndex: number) => {
                          if (indexFieldGroupInGroup === fieldIndex) {
                            return {
                              ...f,
                              isKey: e.target.checked,
                            };
                          }
                          return f;
                        }),
                      };
                    } else {
                      return {
                        ...group,
                        isKey: e.target.checked,
                      };
                    }
                  }
                  return group;
                });
                setForm(newForm);
              }}
              onBlur={() => {
                setButtonSave(true)
              }}
            />

          </div>
        )}

        <ButtonGetPathField
          onClick={() => {
            const jsonForm = generateJSON(
              undefined,
              false,
              "data",
              "",
              "",
              "",
              "",
              "",
              form
            )
            getPathField(
              field.id,
              clientId,
              ambient,
              formId,
              authorization,
              groupId || '',
              jsonForm,
            )
          }}
        />
      </div>

      <button
        type='button'
        onClick={() => {
          if (outsideTheGroup) {
            handleRemoveField(
              fieldGroupInGroup.id,
              field.uuid?.toString() || '',
              form,
              setForm,
              fieldGroupInGroup.uuid || '',
              setButtonSave
            )
          } else {
            handleRemoveFieldInGroup(
              fieldGroupInGroup.uuid,
              field.uuid?.toString() || '',
              form,
              setForm,
              fieldGroupInGroup.uuid || '',
              setButtonSave
            )
          }
        }}
        className={style.removeButton}
        title='Remover Campo'
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>

    </div>
  )
}
