import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import { Helmet } from 'react-helmet-async';

import {
  createForm,
} from '../../utils/functions/functionsForms'

import {
  getFormByIdItemIdPayload,
  getSkillId,
  getTabId,
} from '../../utils/functions/functionsLoadDataForm'

import {
  handleInputNameChange,
  handleInputSkillChange,
  handleInputTabChange,
} from '../../utils/functions/functionsInputs'

import { useAuthContext } from '../../hooks/auth'
import { InputsInfosPages, InputSkill, InputTab } from '../../components/ui/inputs'
import { ButtonCreateFormPayload } from '../../components/ui/buttons'

export default function CreateTemplate() {

  const { authorizationRA, authorization, user } = useAuthContext()
  const ambient = user?.ambient as string;
  const clientId = user?.clientId as string;
  const [nameForm, setNameForm] = useState('')
  const [skillIdForm, setSkillIdForm] = useState('')
  const [skillName, setSkillName] = useState('')
  const [tabIdForm, setTabIdForm] = useState('')
  const [tabName, setTabName] = useState('')
  const [idForm, setIdForm] = useState('')
  const [forms, setForms] = useState('')
  const [loadingButtonCreate, setLoadingButtonCreate] = useState(false)
  const [loadingPage, setLoadingPage] = useState(false);
  const [allForm, setAllForm] = useState<any[]>([]);

  const useIsomorphicEffect = useEffect;
  const [cacheCleared, setCacheCleared] = useState(false);

  useIsomorphicEffect(() => {
    const executeClearCache = async () => {
      await clearCacheClient();
    };
    executeClearCache();

  }, []);

  const clearCacheClient = async () => {
    try {
      const cacheKeys = await caches.keys();

      if (cacheKeys.length > 0) {
        await Promise.all(cacheKeys.map(key => caches.delete(key)));
        setCacheCleared(true);

        // if (!window.location.search.includes('reloaded=true')) {
        //   window.location.replace(window.location.href + '?reloaded=true');
        // }
      }
    } catch (error: any) {
      return error
    }
  };

  const handleSearchForm = async () => {
    setLoadingPage(true);

    if (!idForm) {
      setNameForm('');
      setSkillIdForm('');
      setTabIdForm('');
    } else {
      await getFormByIdItemIdPayload(clientId, idForm, ambient, setNameForm, setTabIdForm, setSkillIdForm, setIdForm, setAllForm);
    }
    setLoadingPage(false);
  };

  return (
    <div>
      <Helmet>
        <title>Tech Forms - Criar fomulário por payload</title>
      </Helmet>

      <div className={style.container}>

        <div className={style.inputsHeader}>

          <div className={style.containerRowObject}>
            <div className={style.rowObjects}>
              <p className={style.titles}>id</p>
              <InputsInfosPages
                placeholder='Insira o id do formulário'
                type='text'
                value={idForm}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIdForm(event.target.value)
                  // handleSearchForm()
                }}
                onBlur={() => handleSearchForm()}
              />
            </div>
          </div>

          <div className={style.containerRowObject}>
            <div className={style.rowObjects}>
              <p className={style.titles}>Nome</p>
              <InputsInfosPages
                placeholder='Insira o nome do formulário'
                type='text'
                value={nameForm}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputNameChange(event, setNameForm)
                }
              />
            </div>
          </div>

          <div className={style.containerRowObject}>
            <div className={style.rowObjects}>
              <p className={style.titles}>SkillId</p>
              <InputSkill
                placeholder='Insira a skillId'
                type='text'
                skillName={skillName}
                value={skillIdForm}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputSkillChange(event, setSkillIdForm)
                }
                onBlur={() => getSkillId(skillIdForm, authorization, ambient, setSkillName)}
              />
            </div>
          </div>

          <div className={style.containerRowObject}>
            <div className={style.rowObjects}>
              <p className={style.titles}>TabId</p>
              <InputTab
                placeholder='Insira a tabId'
                type='text'
                tabName={tabName}
                value={tabIdForm}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputTabChange(event, setTabIdForm)
                }
                onBlur={() => getTabId(tabIdForm, authorization, ambient, setTabName)}
              />
            </div>
          </div>

          <ButtonCreateFormPayload
            loadingButtonCreate={loadingButtonCreate}
            onClick={() => {
              createForm(
                setLoadingButtonCreate,
                forms,
                setForms,
                authorization,
                authorizationRA,
                nameForm,
                skillIdForm,
                tabIdForm,
                idForm,
                ambient,
                setTabName,
                setSkillName,
                setIdForm,
                setNameForm,
                setSkillIdForm,
                setTabIdForm
              )
            }} />

        </div>

        <textarea
          value={forms}
          onChange={(e) => setForms(e.target.value)}
          className={style.jsonTextarea}
          placeholder="Insira o payload do formulário"
        />

      </div>
    </div>
  )
}