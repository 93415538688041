import { useState, useEffect } from 'react'
import { useAuthContext } from '../../hooks/auth';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { FiLogOut } from 'react-icons/fi'
import style from './styles.module.scss'
import logoImg from '../../assets/tech4humans.png';

export function Header({ home = false }) {
  const { user, signOut } = useAuthContext()
  const navigate = useNavigate()
  const [formId, setFormId] = useState<string | null>(null)
  const [timeLeft, setTimeLeft] = useState<number>(0)

  const clientName = user?.clientName
  const userName = user?.userName
  const clientId = user?.clientId
  const ambient = user?.ambientLogged

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const fromListForms = queryParams.get('formId');
    setFormId(fromListForms);

    // Função para atualizar o tempo restante
    const updateTimeLeft = () => {
      const logoutTime = localStorage.getItem("LOGOUT_TIMEOUT_KEY");
      if (logoutTime) {
        const timeRemaining = parseInt(logoutTime) - new Date().getTime();
        setTimeLeft(timeRemaining > 0 ? timeRemaining : 0);
      }
    };

    // Atualiza o tempo restante a cada segundo
    const intervalId = setInterval(updateTimeLeft, 1000);

    return () => clearInterval(intervalId);
  }, [clientId, navigate]);

  // Função para formatar o tempo restante
  const formatTime = (time: number) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <header className={style.headerContainer}>

      <div className={style.headerContent}>

        <div className={style.divName}>

          <Link to='/section'>
            <img src={logoImg}
              width={170}
              height={60}
              alt='Logo Tech' />
          </Link>

          <div>
            <h4 className={style.session}>Sessão</h4>
            <h3 className={style.session}>{formatTime(timeLeft)}</h3>
          </div>

          <div className={style.infoCompany}>
            <div>
              <label className={style.session}>Cliente:</label>
              <h4 className={style.session}>{clientName/* ?.toUpperCase() */}</h4>
            </div>
          </div>

          <div className={style.infoCompany}>
            <div>
              <label className={style.session}>Ambiente:</label>
              <h4 className={style.session}>{ambient}</h4>
            </div>
          </div>

          <div className={style.infoCompany}>
            <div>
              <label className={style.session}>Usuário Logado:</label>
              <h4 className={style.session}>{user?.userName}</h4>
            </div>
          </div>

        </div>

      </div>

      <div className={style.headerContentButtons}>

        <nav className={style.menuNav}>
          <div className={style.info}>
            {/* <h5>
              <span>Email:</span> {user?.userName}
            </h5> */}
            <h5>
              {/*  <span>Email:</span>  */}{user?.userEmail}
            </h5>
          </div>
          <button onClick={signOut}>
            <FiLogOut style={{ color: '#fff' }} size={24} />
          </button>
        </nav>


      </div>

    </header>
  )
}
