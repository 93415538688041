import React, { FormEvent, useEffect, useState, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
// import ReCAPTCHA from 'react-google-recaptcha';
import logo from '../../assets/tech4humans.png';
import { SignInProps } from '../../utils/functions/functionsInterfaces';
import { displayWarning } from '../../utils/functions/messageToast';
import { useAuthContext } from '../../hooks/auth';
import { Select } from '../../components/ui/selects';
import { Input } from '../../components/ui/inputs';
import style from './style.module.scss';
import { ButtonLogin } from '../../components/ui/buttons';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Login: React.FC = () => {
  const { signIn } = useAuthContext();
  const [instance, setInstance] = useState('');
  const [ambient, setAmbient] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [userAutomation, setUserAutomation] = useState(true);
  // const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [token, setToken] = useState('');
  const [cacheCleared, setCacheCleared] = useState(false);
  // const recaptchaRef = useRef<any>(null);
  const useIsomorphicEffect = useEffect;

  // const keyWebSite = import.meta.env.MODE === 'production' ? import.meta.env.VITE_RECAPTCHA_KEY_PROD : import.meta.env.VITE_RECAPTCHA_KEY_DEV;
  // const keyWebSite = import.meta.env.VITE_RECAPTCHA_KEY_PROD;
  // console.log('keyWebSite', keyWebSite);

  useEffect(() => {
    localStorage.removeItem('Authorization');
    localStorage.removeItem('AuthorizationRA');
    localStorage.removeItem('Authorization_to');
    localStorage.removeItem('AuthorizationRA_to');
    localStorage.removeItem('SESSION_KEY');
    localStorage.removeItem('LOGOUT_TIMEOUT_KEY');
    localStorage.removeItem('ambient_to');
    localStorage.removeItem('client_to');
    localStorage.removeItem('email_to');
    localStorage.removeItem('instance_to');
  }, []);

  useIsomorphicEffect(() => {
    const executeClearCache = async () => {
      await clearCacheClient();
    };
    executeClearCache();

    // if (recaptchaRef.current) {
    //   recaptchaRef.current.reset();
    // }

    const savedEmail = localStorage.getItem('email');
    const savedInstance = localStorage.getItem('instance');
    const savedAmbient = localStorage.getItem('ambient');

    if (savedEmail) setEmail(savedEmail);
    if (savedInstance) setInstance(savedInstance);
    if (savedAmbient) setAmbient(savedAmbient);
  }, []);

  const clearCacheClient = async () => {
    try {
      const cacheKeys = await caches.keys();

      if (cacheKeys.length > 0) {
        await Promise.all(cacheKeys.map(key => caches.delete(key)));
        setCacheCleared(true);

        // if (!window.location.search.includes('reloaded=true')) {
        //   window.location.replace(window.location.href + '?reloaded=true');
        // }
      }
    } catch (error: any) {
      return error
    }
  };

  useIsomorphicEffect(() => {
    localStorage.setItem('email', email);
    localStorage.setItem('instance', instance);
    localStorage.setItem('ambient', ambient);
  }, [email, instance, ambient]);

  const onSubmitWithReCAPTCHA = async (event: FormEvent) => {
    event.preventDefault();

    if (ambient === '') {
      displayWarning('Selecione o ambiente!');
      return;
    }

    if (instance === '') {
      displayWarning('Preencha o domínio da empresa!');
      return;
    }

    if (email === '') {
      displayWarning('Preencha o email!');
      return;
    }

    if (password === '') {
      displayWarning('Preencha a senha!');
      return;
    }

    const dataLoginProps: SignInProps = {
      instance,
      email,
      password,
      ambient,
      recaptcha_token: token,
      checked: userAutomation,
    };

    setLoading(true);

    // if (!token) {
    //   setRefreshReCaptcha(true);
    //   console.log("aqui");

    //   const timer = setTimeout(() => {
    //     setRefreshReCaptcha(false);
    //   }, 500);
    //   clearTimeout(timer);

    //   await signIn(dataLoginProps);
    //   setLoading(false);
    //   return;
    // }

    await signIn(dataLoginProps);
    setLoading(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAmbient(event.target.value);
  };

  // const onVerify = useCallback((token: string) => {
  //   console.log('ReCAPTCHA token:', token);
  //   setToken(token);
  // }, []);

  return (
    <>
      {/* <GoogleReCaptchaProvider
        reCaptchaKey={keyWebSite}
        container={{
          element: 'login',
          parameters: { badge: 'bottomright', theme: 'dark' }
        }}
      > */}

      <Helmet>
        <title>Tech Forms - Login</title>
      </Helmet>

      <div className={style.containerCenter}>
        <div className={style.login}>
          <img src={logo} alt="Logo Tech" />

          <form onSubmit={onSubmitWithReCAPTCHA}>

            <Select value={ambient} onChange={(event) => {
              handleChange(event);
            }}>
              <option value="" disabled>
                Selecione o ambiente
              </option>
              <option value="prod">Produção</option>
              <option value="hml" /* disabled */>Homologação</option>
            </Select>

            <Input
              placeholder="Domínio da empresa"
              type="text"
              value={instance}
              onChange={(e) => setInstance(e.target.value)}
              autoComplete="company-domain"
            />

            <Input
              placeholder="E-mail"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />

            <Input
              placeholder="Senha"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />

            <ButtonLogin type="submit" loading={loading}>
              Login
            </ButtonLogin>
          </form>

          {/* <GoogleReCaptcha
            onVerify={onVerify}
            refreshReCaptcha={refreshReCaptcha}
            key={keyWebSite}
            action='login'
          /> */}

        </div>
      </div>

      {/* </GoogleReCaptchaProvider> */}
    </>
  );
};

export default Login;