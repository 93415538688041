import { useNavigate } from 'react-router-dom'
import { createContext, useEffect, useState } from 'react'
import { setupApiGetFormsAndLogin } from '../services/setupApis'
import { displayError, displayErrorExpired, displaySuccess } from '../utils/functions/messageToast'
import { LoggedInUserProps, AuthContextData, SignInProps, AuthProviderProps } from '../utils/functions/functionsInterfaces'

export const AUTHORIZATION_KEY = 'Authorization' as const
export const AUTHORIZATION_RA_KEY = 'AuthorizationRA' as const

const AUTHORIZATION_RA_KEY_TO = 'AuthorizationRA_to' as const
const AUTHORIZATION_KEY_TO = 'Authorization_to' as const
const AMBIENT_TO = 'ambient_to' as const
const CLIENT_TO = 'client_to' as const
const EMAIL_TO = 'email_to' as const
const INSTANCE_TO = 'instance_to' as const

export const SESSION_KEY = 'SESSION_KEY' as const
export const LOGOUT_TIMEOUT_KEY = "LOGOUT_TIMEOUT_KEY" as const;

export const AuthContext = createContext({} as AuthContextData)

export function AuthProvider({ children }: AuthProviderProps) {

  const navigate = useNavigate()

  const [authorizationRA, setAuthorizationRA] = useState<string>('')
  const [authorization, setAuthorization] = useState<string>('')
  const [user, setUser] = useState<LoggedInUserProps | null>(null)
  const [isManualSignOut, setIsManualSignOut] = useState(false);

  function setupAutoLogout(timeout: number) {
    const logoutTime = new Date().getTime() + timeout;
    localStorage.setItem(LOGOUT_TIMEOUT_KEY, logoutTime.toString());
  }

  useEffect(() => {
    function signOutAuto() {
      if (isManualSignOut) return;
      try {
        localStorage.removeItem(AUTHORIZATION_KEY);
        localStorage.removeItem(AUTHORIZATION_RA_KEY);

        localStorage.removeItem(AUTHORIZATION_KEY_TO);
        localStorage.removeItem(AUTHORIZATION_RA_KEY_TO);
        localStorage.removeItem(CLIENT_TO);
        localStorage.removeItem(AMBIENT_TO);
        localStorage.removeItem(EMAIL_TO);
        localStorage.removeItem(INSTANCE_TO);

        localStorage.removeItem(SESSION_KEY);
        localStorage.removeItem(LOGOUT_TIMEOUT_KEY);

        setAuthorization("");
        setAuthorizationRA("");
        setUser(null);
        navigate("/");
        displayErrorExpired("Sessão expirada!");
      } catch (error) {
        displayError("Erro ao deslogar!");
      }
    }

    const checkSession = () => {
      const authorization = localStorage.getItem(AUTHORIZATION_KEY);
      const authorizationRA = localStorage.getItem(AUTHORIZATION_RA_KEY);
      const userData = localStorage.getItem(SESSION_KEY);
      const storedLogoutTime = localStorage.getItem(LOGOUT_TIMEOUT_KEY);

      const isLoginPage = window.location.pathname === "/"; // Verifica se está na tela de login

      // Evita sign-out automático na tela de login
      if (isLoginPage) return;

      // Verifica se os dados obrigatórios estão presentes
      if (!authorization || !authorizationRA || !userData || !storedLogoutTime) {
        signOutAuto();
        return;
      }

      // Verifica se o tempo armazenado é válido
      const currentTime = new Date().getTime();
      if (parseInt(storedLogoutTime) <= currentTime) {
        signOutAuto();
        return;
      }

      // Caso contrário, restaura o estado
      setAuthorization(authorization);
      setAuthorizationRA(authorizationRA);
      setUser(JSON.parse(userData));
    };

    // Verifica a sessão e configura o logout automático
    checkSession();

    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      const storedLogoutTime = localStorage.getItem(LOGOUT_TIMEOUT_KEY);

      if (storedLogoutTime && parseInt(storedLogoutTime) <= currentTime) {
        clearInterval(intervalId); // Limpa o intervalo antes de sair

        signOutAuto();
      }
    }, 1000); //Verifica a cada segundo e meio

    return () => clearInterval(intervalId);
  }, [navigate]);

  async function signIn(credentials: SignInProps) {
    try {

      const apiGetFormsAndLogin = setupApiGetFormsAndLogin(
        undefined,
        AuthContext
      )

      const response = await apiGetFormsAndLogin.post('/login-tech', {
        slug: credentials.instance,
        email: credentials.email,
        password: credentials.password,
        ambient: credentials.ambient,
        recaptcha_token: credentials.recaptcha_token,
        checked: credentials.checked,
      })

      let ambientLogged = ''
      if (credentials.ambient === 'prod') {
        ambientLogged = 'Produção'
      } else if (credentials.ambient === 'hml') {
        ambientLogged = 'Homologação'
      }

      const loggedInUserProps = {
        clientName: response.data.company.name,
        clientId: response.data.company.id,
        userName: response.data.user.name,
        ambient: credentials.ambient,
        ambientLogged,
        userEmail: response.data.user.email,
        instance: credentials.instance,
        loginTimestamp: new Date().getTime(),
      }

      setUser(loggedInUserProps as LoggedInUserProps)

      localStorage.setItem(
        AUTHORIZATION_KEY,
        response.data.tokens.Authorization
      )
      localStorage.setItem(
        AUTHORIZATION_RA_KEY,
        response.data.tokens.AuthorizationRA
      )
      localStorage.setItem(SESSION_KEY, JSON.stringify(loggedInUserProps))

      // Define o tempo limite para 5 horas
      setupAutoLogout(5 * 60 * 60 * 1000);
      // setupAutoLogout(1 * 60 * 1000); // 1minuto teste

      displaySuccess('Login efetuado com sucesso!')
      navigate(`/section/listForms?clientId=${response.data.company.id}`);
    } catch (error) {
      displayError('Erro ao fazer login!')
    }
  }

  function signOut() {
    try {
      setIsManualSignOut(true);
      localStorage.removeItem(AUTHORIZATION_KEY);
      localStorage.removeItem(AUTHORIZATION_RA_KEY);

      localStorage.removeItem(AUTHORIZATION_KEY_TO);
      localStorage.removeItem(AUTHORIZATION_RA_KEY_TO);
      localStorage.removeItem(CLIENT_TO);
      localStorage.removeItem(AMBIENT_TO);
      localStorage.removeItem(EMAIL_TO);
      localStorage.removeItem(INSTANCE_TO);

      localStorage.removeItem(SESSION_KEY);
      localStorage.removeItem(LOGOUT_TIMEOUT_KEY);

      setAuthorization("");
      setAuthorizationRA("");
      setUser(null);
      navigate("/");
    } catch (error) {
      displayError("Erro ao deslogar!");
    }
  }

  return (
    <AuthContext.Provider value={{ signIn, signOut, authorizationRA, authorization, user }}>
      {children}
    </AuthContext.Provider>
  )
}
